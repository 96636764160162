import clsx from 'clsx'
import { Price } from '~common/components/product/Price'
import {
  CurrencyCode,
  SearchResultPrice,
  StripeSubscriptionInterval,
} from '~common/generated/storefront-graphql'
import { capitalizeFirstLetter } from '~common/utils/format/capitalizeFirstLetter'

export function SubscriptionPrice({
  priceWithTax,
  // pricePrice,
  interval,
  downpayment = 0,
  paidUpFront,
  currencyCode,
  quantity,
  highlight,
  prefix = true,
  className,
}: {
  priceWithTax: number | SearchResultPrice
  currencyCode?: CurrencyCode
  paidUpFront: boolean
  interval?: StripeSubscriptionInterval | undefined
  downpayment?: number | 0
  quantity: number | 1
  highlight?: boolean
  prefix?: boolean
  className?: string
}) {
  let subscriptionPricePrefix = null
  let subscriptionPricePostfix = null

  const price =
    // @ts-ignore this is correct
    typeof priceWithTax !== 'number' ? priceWithTax.min : priceWithTax

  if (!paidUpFront) {
    if (prefix) {
      subscriptionPricePrefix = (
        <span
          className={clsx('mr-1.5 font-normal', {
            'text-gray-400': highlight,
            ' text-gray-500': !highlight,
          })}
        >
          {capitalizeFirstLetter(`${interval}`)}ly
        </span>
      )
    } else {
      subscriptionPricePostfix = (
        <span
          className={clsx('ml-1.5 font-normal', {
            'text-gray-400': highlight,
            ' text-gray-500': !highlight,
          })}
        >
          / {interval}
        </span>
      )
    }
  }
  if (!paidUpFront) {
    const initialFee = (
      <span
        className={clsx('pr-1.5 font-normal', {
          'pr-1.5': prefix,
          'pl-1.5': !prefix,
          'text-gray-400': highlight,
          'text-gray-500': !highlight,
        })}
      >
        Initial fee
      </span>
    )
    return (
      <>
        <p className="whitespace-nowrap text-right">
          {subscriptionPricePrefix}
          <Price
            currencyCode={currencyCode}
            priceWithTax={price}
            className={className}
          />
          {subscriptionPricePostfix}
        </p>
        {downpayment ? (
          <p className="whitespace-nowrap text-right">
            {prefix ? initialFee : null}
            <Price
              currencyCode={currencyCode}
              priceWithTax={downpayment}
              className={className}
            />
            {prefix ? null : initialFee}
          </p>
        ) : null}
      </>
    )
  }
  return (
    <Price
      currencyCode={currencyCode}
      priceWithTax={price}
      className={className}
    ></Price>
  )
}
